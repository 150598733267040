import { render, staticRenderFns } from "./updateLog.vue?vue&type=template&id=1abb84ac&scoped=true&"
import script from "./updateLog.vue?vue&type=script&lang=js&"
export * from "./updateLog.vue?vue&type=script&lang=js&"
import style0 from "./updateLog.vue?vue&type=style&index=0&id=1abb84ac&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1abb84ac",
  null
  
)

export default component.exports