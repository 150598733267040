<template>
  <div class="updateLog">
    <el-row>
      <div class="content">
        <div class="content_item" v-for="(version, index) in versions" :key="index">
          <div class="content_title">
            {{ version.version }} <span v-if="version.time">{{ version.time }}</span>
          </div>
          <div class="content_main">
            <div v-if="version.newFeatures && version.newFeatures.length > 0">
              <p>新增</p>
              <p v-for="(feature, index) in version.newFeatures" :key="index">{{ feature }}</p>
            </div>
            <div v-if="version.fixFeatures && version.fixFeatures.length > 0">
              <p>修复</p>
              <p v-for="(feature, index) in version.fixFeatures" :key="index">{{ feature }}</p>
            </div>
          </div>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Update",
  data() {
    return {
      versions: [
        {
          version: "v1.0.0",
          newFeatures: [
            "app：",
            "1. 开发视频滑动浏览及入口",
            "2.开发视频分享，互动元素、分支视频点击预览",
            "pc配置端：",
            "1. 增加互动元素配置（包括行情、工具、资讯、商品、外链）",
            "2. 增加视频解析",
            "3. 增加视频配置预览",
            "4. 增加视频发布、下架、删除，管理",
          ],
        },
        {
          version: "v1.1.0",
          newFeatures: [
            "1. 配置平台权限管理",
            "2. 素材中心及互动元素配置（包括分支视频、投票、报名、图片、名片）",
            "3. 互动元素APP展示优化",
            "4. 增加了数据统计功能（包括视频概览、互动元素趋势统计等）",
            "5. 增加了指定账号自动发布功能",
            "6. 增加了配置页视频私域分享功能",
          ],
        },
        {
          version: "v1.2.0",
          newFeatures: [
            "1. 优化官网介绍；",
            "2.增加配置视频分类；",
            "3.增加视频搜索功能；",
            "4.提升部分使用体验；",
          ],
          fixFeatures: [
            "解决已知bug。",
          ]
          
        },
      ],
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.updateLog {
  padding: 40px 25% 60px;
  min-height: 750px;
  background: #f7f8fa;
  color: #252525;
  .content_item {
    padding: 16px 24px;
    background: #fff;
    border-radius: 12px;
    border: 1px solid #d9dce6;
    margin-bottom: 12px;
    .content_title {
      font-size: 24px;
      font-weight: bold;
      span {
        font-size: 14px;
        font-weight: normal;
      }
    }
    .content_main {
      color: #252525;
      font-size: 14px;
    }
  }
}
</style>
